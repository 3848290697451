import React, { useState, useEffect } from "react";
import "../scss/AboutPage.scss";
import Seo from "../components/seo/seo";

import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";
import PictureDescription from "../shared/picture-description/PictureDescription";
import AccordionsList from "../shared/accordions-list/AccordionsList";

import axios from "../http/axios";

const AboutPage = () => {
  const [achievements, setAchievements] = useState([]);
  const [about, setAbout] = useState([]);

  useEffect(() => {
    axios.get(`/achievements`).then((res) => {
      setAchievements(res.data);
    });
    axios.get(`/content/about`).then((res) => {
      setAbout(res.data);
    });
  }, []);
  return (
    <div className="about-page">
      <Seo title="Brian Lasky -" />
      <BubblesBgHeader text="Brian Lasky" />
      <div className="content-block container mt-5">
        <PictureDescription item={about} />
        <div className="row mb-5">
          <h2 className="col-12 mb-5">Achievements</h2>
          <AccordionsList items={achievements} />
          <div className="opacity-0 mt-7">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
