import * as React from "react";
import "./PictureDescription.scss";

import picture from "../../images/AboutBrian-1024x643.jpg";

const PictureDescription = ({ item }) => {
  return (
    <div className="picture-description">
      <div className="row">
        <div className="col-lg-8">
          <img src={item.resolvedImageUrl} alt="Brian Lasky" />
        </div>
        <div
          className="col text"
          dangerouslySetInnerHTML={{ __html: item.text }}
        ></div>
      </div>
    </div>
  );
};
export default PictureDescription;
